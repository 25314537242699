.slidea {
  background: #1a1a1a;
  height: 600px;
}

.slidea.slidea-inverse {
  background: #1a1a1a;
}

.slidea.slidea-inverse .slidea-loader-wrapper {
  background: #1a1a1a;
}

.slidea.slidea-inverse .slidea-loader-wrapper .slidea-loader-inner {
  border: 1px solid black !important;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.75);
}

.slidea.slidea-inverse .slidea-loader-wrapper .slidea-loader-inner .slidea-loader-text .slidea-loader-title {
  color: #f2f2f2;
}

.slidea.slidea-inverse .slidea-loader-wrapper .slidea-loader-inner .slidea-loader-text .slidea-loader-subtitle {
  color: #bfbfbf;
}

@media screen and (max-width: 767px) {
  .slidea {
    height: 300px;
  }
}

@media screen and (max-width: 979px) {
  .slidea {
    height: 360px;
  }
}

@media screen and (max-width: 1200px) {
  .slidea {
    height: 450px;
  }
}

.slidea .slidea-loader-wrapper {
  background: #f2f2f2;
}

.slidea .slidea-loader-wrapper .slidea-loader-text .slidea-loader-title {
  color: #111;
}

.slidea .slidea-loader-wrapper .slidea-loader-text .slidea-loader-subtitle {
  color: #333;
}

.slidea .slidea-loader-wrapper .slidea-loader {
  margin-top: -80px;
  margin-left: -40px;
  width: 80px;
  height: 80px;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner {
  border: 1px solid #ffffff;
  border-radius: 4px;
  overflow: hidden;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile {
  height: 80px;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile:nth-child(1) {
  -webkit-animation: loader-tile1 1.5s linear infinite;
  -moz-animation: loader-tile1 1.5s linear infinite;
  -ms-animation: loader-tile1 1.5s linear infinite;
  -o-animation: loader-tile1 1.5s linear infinite;
  animation: loader-tile1 1.5s linear infinite;
  background: #3E2D54;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile:nth-child(2) {
  -webkit-animation: loader-tile2 1.5s linear infinite;
  -moz-animation: loader-tile2 1.5s linear infinite;
  -ms-animation: loader-tile2 1.5s linear infinite;
  -o-animation: loader-tile2 1.5s linear infinite;
  animation: loader-tile2 1.5s linear infinite;
  background: #40587B;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile:nth-child(3) {
  -webkit-animation: loader-tile3 1.5s linear infinite;
  -moz-animation: loader-tile3 1.5s linear infinite;
  -ms-animation: loader-tile3 1.5s linear infinite;
  -o-animation: loader-tile3 1.5s linear infinite;
  animation: loader-tile3 1.5s linear infinite;
  background: #4D90A3;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile:nth-child(4) {
  -webkit-animation: loader-tile4 1.5s linear infinite;
  -moz-animation: loader-tile4 1.5s linear infinite;
  -ms-animation: loader-tile4 1.5s linear infinite;
  -o-animation: loader-tile4 1.5s linear infinite;
  animation: loader-tile4 1.5s linear infinite;
  background: #7CB694;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile:nth-child(5) {
  -webkit-animation: loader-tile5 1.5s linear infinite;
  -moz-animation: loader-tile5 1.5s linear infinite;
  -ms-animation: loader-tile5 1.5s linear infinite;
  -o-animation: loader-tile5 1.5s linear infinite;
  animation: loader-tile5 1.5s linear infinite;
  background: #DCD280;
}

.slidea .slidea-progress {
  height: 5px;
}

.slidea .slidea-progress .slidea-progress-bar {
  height: 5px;
}

.slidea .slidea-progress.slidea-progress-light {
  background: rgba(255, 255, 255, 0.5);
}

.slidea .slidea-progress.slidea-progress-light .slidea-progress-bar {
  background: white;
}

.slidea .slidea-progress.slidea-progress-dark {
  background: rgba(0, 0, 0, 0.25);
}

.slidea .slidea-progress.slidea-progress-dark .slidea-progress-bar {
  background: rgba(0, 0, 0, 0.5);
}

.slidea:hover .slidea-control {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.slidea .slidea-control {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin-top: -54px;
  width: 54px;
  height: 108px;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-text {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0.33;
  -khtml-opacity: 0.33;
  opacity: 0.33;
  font-size: 18px;
  line-height: 108px;
  width: 54px;
  height: 108px;
}

.slidea .slidea-control:hover .slidea-control-inner .slidea-control-text {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.slidea .slidea-control.slidea-prev:hover .slidea-control-inner .slidea-control-thumbnail {
  -webkit-transform: translate3d(54px, 0, 0);
  -moz-transform: translate3d(54px, 0, 0);
  -ms-transform: translate3d(54px, 0, 0);
  -o-transform: translate3d(54px, 0, 0);
  transform: translate3d(54px, 0, 0);
}

.slidea .slidea-control.slidea-controls-light .slidea-control-inner .slidea-control-text {
  background: #fff;
  color: #181619;
}

.slidea .slidea-control.slidea-controls-light:hover .slidea-control-inner .slidea-control-text {
  background: #fff;
  color: #181619;
}

.slidea .slidea-control.slidea-controls-dark .slidea-control-inner .slidea-control-text {
  background: #181619;
  color: #fff;
}

.slidea .slidea-control.slidea-controls-dark:hover .slidea-control-inner .slidea-control-text {
  background: #181619;
  color: #fff;
}

.slidea .slidea-control.slidea-controls-alternate .slidea-control-inner .slidea-control-text {
  background: #181619;
  color: #fff;
}

.slidea .slidea-control.slidea-controls-alternate:hover .slidea-control-inner .slidea-control-text {
  background: #fff;
  color: #181619;
}

.slidea .slidea-scroller-wrapper {
  position: absolute;
  bottom: 25px;
  cursor: pointer;
  z-index: 4;
}

.slidea .slidea-scroller-wrapper.slidea-scroller-left {
  left: 25px;
}

.slidea .slidea-scroller-wrapper.slidea-scroller-center {
  left: 50%;
  margin-left: -13px;
}

.slidea .slidea-scroller-wrapper.slidea-scroller-right {
  right: 25px;
}

.slidea .slidea-scroller-1 {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  width: 26px;
  height: 50px;
}

.slidea .slidea-scroller-1:hover {
  -webkit-transform: scale(1.5) translate3d(0, -5px, 0);
  -moz-transform: scale(1.5) translate3d(0, -5px, 0);
  -ms-transform: scale(1.5) translate3d(0, -5px, 0);
  -o-transform: scale(1.5) translate3d(0, -5px, 0);
  transform: scale(1.5) translate3d(0, -5px, 0);
}

.slidea .slidea-scroller-1::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: "";
  display: block;
  margin-top: 25px;
  margin-left: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 15px;
  height: 15px;
}

.slidea .slidea-scroller-1::before {
  -webkit-animation: slideascroller1 1.5s linear infinite;
  -moz-animation: slideascroller1 1.5s linear infinite;
  -ms-animation: slideascroller1 1.5s linear infinite;
  -o-animation: slideascroller1 1.5s linear infinite;
  animation: slideascroller1 1.5s linear infinite;
  content: "";
  display: block;
  background: #fff;
  margin-top: 5px;
  margin-left: 9px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.slidea .slidea-scroller-2 {
  display: block;
  background: transparent;
  border-width: 2px;
  border-color: #fff;
  border-style: solid;
  border-radius: 13px;
  width: 26px;
  height: 50px;
}

.slidea .slidea-scroller-2::before {
  -webkit-animation: slideascroller2 1.5s linear infinite;
  -moz-animation: slideascroller2 1.5s linear infinite;
  -ms-animation: slideascroller2 1.5s linear infinite;
  -o-animation: slideascroller2 1.5s linear infinite;
  animation: slideascroller2 1.5s linear infinite;
  content: "";
  display: block;
  position: absolute;
  background: #fff;
  margin-top: 5px;
  margin-left: 7px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

@-webkit-keyframes slideascroller1 {
  0% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) translate3d(0, 0, 0);
    -ms-transform: scale(0) translate3d(0, 0, 0);
    -o-transform: scale(0) translate3d(0, 0, 0);
    transform: scale(0) translate3d(0, 0, 0);
  }
  75% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 30px, 0);
    -moz-transform: scale(1) translate3d(0, 30px, 0);
    -ms-transform: scale(1) translate3d(0, 30px, 0);
    -o-transform: scale(1) translate3d(0, 30px, 0);
    transform: scale(1) translate3d(0, 30px, 0);
  }
}

@-moz-keyframes slideascroller1 {
  0% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) translate3d(0, 0, 0);
    -ms-transform: scale(0) translate3d(0, 0, 0);
    -o-transform: scale(0) translate3d(0, 0, 0);
    transform: scale(0) translate3d(0, 0, 0);
  }
  75% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 30px, 0);
    -moz-transform: scale(1) translate3d(0, 30px, 0);
    -ms-transform: scale(1) translate3d(0, 30px, 0);
    -o-transform: scale(1) translate3d(0, 30px, 0);
    transform: scale(1) translate3d(0, 30px, 0);
  }
}

@keyframes slideascroller1 {
  0% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) translate3d(0, 0, 0);
    -ms-transform: scale(0) translate3d(0, 0, 0);
    -o-transform: scale(0) translate3d(0, 0, 0);
    transform: scale(0) translate3d(0, 0, 0);
  }
  75% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 30px, 0);
    -moz-transform: scale(1) translate3d(0, 30px, 0);
    -ms-transform: scale(1) translate3d(0, 30px, 0);
    -o-transform: scale(1) translate3d(0, 30px, 0);
    transform: scale(1) translate3d(0, 30px, 0);
  }
}

@-webkit-keyframes slideascroller2 {
  0% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) translate3d(0, 0, 0);
    -ms-transform: scale(0) translate3d(0, 0, 0);
    -o-transform: scale(0) translate3d(0, 0, 0);
    transform: scale(0) translate3d(0, 0, 0);
  }
  75% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 30px, 0);
    -moz-transform: scale(1) translate3d(0, 30px, 0);
    -ms-transform: scale(1) translate3d(0, 30px, 0);
    -o-transform: scale(1) translate3d(0, 30px, 0);
    transform: scale(1) translate3d(0, 30px, 0);
  }
}

@-moz-keyframes slideascroller2 {
  0% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) translate3d(0, 0, 0);
    -ms-transform: scale(0) translate3d(0, 0, 0);
    -o-transform: scale(0) translate3d(0, 0, 0);
    transform: scale(0) translate3d(0, 0, 0);
  }
  75% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 30px, 0);
    -moz-transform: scale(1) translate3d(0, 30px, 0);
    -ms-transform: scale(1) translate3d(0, 30px, 0);
    -o-transform: scale(1) translate3d(0, 30px, 0);
    transform: scale(1) translate3d(0, 30px, 0);
  }
}

@keyframes slideascroller2 {
  0% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) translate3d(0, 0, 0);
    -ms-transform: scale(0) translate3d(0, 0, 0);
    -o-transform: scale(0) translate3d(0, 0, 0);
    transform: scale(0) translate3d(0, 0, 0);
  }
  75% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
    filter: "alpha(opacity=(${iefactor}))";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 30px, 0);
    -moz-transform: scale(1) translate3d(0, 30px, 0);
    -ms-transform: scale(1) translate3d(0, 30px, 0);
    -o-transform: scale(1) translate3d(0, 30px, 0);
    transform: scale(1) translate3d(0, 30px, 0);
  }
}

@-webkit-keyframes loader-tile1 {
  0% {
    background: #3E2C53;
  }
  33.3333% {
    background: #AC2B61;
  }
  66.6666% {
    background: #F3E3B4;
  }
}

@-moz-keyframes loader-tile1 {
  0% {
    background: #3E2C53;
  }
  33.3333% {
    background: #AC2B61;
  }
  66.6666% {
    background: #F3E3B4;
  }
}

@keyframes loader-tile1 {
  0% {
    background: #3E2C53;
  }
  33.3333% {
    background: #AC2B61;
  }
  66.6666% {
    background: #F3E3B4;
  }
}

@-webkit-keyframes loader-tile2 {
  0% {
    background: #3F577A;
  }
  33.3333% {
    background: #E33D5F;
  }
  66.6666% {
    background: #BADCC8;
  }
}

@-moz-keyframes loader-tile2 {
  0% {
    background: #3F577A;
  }
  33.3333% {
    background: #E33D5F;
  }
  66.6666% {
    background: #BADCC8;
  }
}

@keyframes loader-tile2 {
  0% {
    background: #3F577A;
  }
  33.3333% {
    background: #E33D5F;
  }
  66.6666% {
    background: #BADCC8;
  }
}

@-webkit-keyframes loader-tile3 {
  0% {
    background: #4C8FA2;
  }
  33.3333% {
    background: #EF7C5E;
  }
  66.6666% {
    background: #7FC7BF;
  }
}

@-moz-keyframes loader-tile3 {
  0% {
    background: #4C8FA2;
  }
  33.3333% {
    background: #EF7C5E;
  }
  66.6666% {
    background: #7FC7BF;
  }
}

@keyframes loader-tile3 {
  0% {
    background: #4C8FA2;
  }
  33.3333% {
    background: #EF7C5E;
  }
  66.6666% {
    background: #7FC7BF;
  }
}

@-webkit-keyframes loader-tile4 {
  0% {
    background: #7BB594;
  }
  33.3333% {
    background: #F9C65F;
  }
  66.6666% {
    background: #EE7B73;
  }
}

@-moz-keyframes loader-tile4 {
  0% {
    background: #7BB594;
  }
  33.3333% {
    background: #F9C65F;
  }
  66.6666% {
    background: #EE7B73;
  }
}

@keyframes loader-tile4 {
  0% {
    background: #7BB594;
  }
  33.3333% {
    background: #F9C65F;
  }
  66.6666% {
    background: #EE7B73;
  }
}

@-webkit-keyframes loader-tile5 {
  0% {
    background: #DBD17F;
  }
  33.3333% {
    background: #F5EF9E;
  }
  66.6666% {
    background: #D4385F;
  }
}

@-moz-keyframes loader-tile5 {
  0% {
    background: #DBD17F;
  }
  33.3333% {
    background: #F5EF9E;
  }
  66.6666% {
    background: #D4385F;
  }
}

@keyframes loader-tile5 {
  0% {
    background: #DBD17F;
  }
  33.3333% {
    background: #F5EF9E;
  }
  66.6666% {
    background: #D4385F;
  }
}

.slidea:hover .slidea-pagination {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.slidea-pagination {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.slidea-pagination.slidea-pagination-inside {
  height: 20px;
}

.slidea-pagination.slidea-pagination-outside {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  margin: 50px auto;
}

.slidea-pagination.slidea-pagination-light .slidea-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
}

.slidea-pagination.slidea-pagination-light .slidea-pagination-bullet.active {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  background: white;
}

.slidea-pagination.slidea-pagination-dark .slidea-pagination-bullet {
  background: rgba(0, 0, 0, 0.25);
}

.slidea-pagination.slidea-pagination-dark .slidea-pagination-bullet.active {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  background: rgba(0, 0, 0, 0.65);
}

.slidea-pagination .slidea-pagination-bullet {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-clip: padding-box;
  margin: 10px;
  width: 10px;
  height: 10px;
}

.slidea-thumbnails {
  background: #000;
}

.slidea-thumbnails-inner {
  display: block;
  margin: 0;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper .slidea-thumbnail {
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper:hover .slidea-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper.active .slidea-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
